// Colors
$green-fb: #2aa360;
$background-color: #f1f3f5;
$background-color-dark: #e9edef;
$border-color: #e3e8eb;
$yellow: #f2c36b;
$yellow-light: findLightColor($yellow);
$yellow-lighter: #fdf6e8;
$yellow-dark: findDarkColor($yellow);
$yellow-invert: findColorInvert($yellow);
$background-color-secondary: #F8F6F4;
// https://coolors.co/f2c36b-f2c36b-2aa360-0097f3-3f51b5
// bulma override
$primary: $green-fb;
$primary-dark: scale-color($primary, $lightness: -20%);
$link: $green-fb;

$secondary: #3F51B5;
$secondary-invert: findColorInvert($secondary);
$secondary-light: findLightColor($secondary);

$custom-colors: (
    "secondary":($secondary, $secondary-invert, $secondary-light),
    "yellow":($yellow, $yellow-invert, $yellow-light),
);

// Spacing
$sp-size-xxs: 0.5rem;
$sp-size-xs: 0.8rem;
$sp-size-s: 1.2rem;
$sp-size-m: 1.6rem;
$sp-size-ml: 2.2rem;
$sp-size-l: 3.2rem;
$sp-size-xl: 4.8rem;

// Layout
$sidebar-width: 14rem;
$toolbar-height: 3.5rem;

// Fonts
$family-sans-serif: 'lato', sans-serif !important;
$font-title: 'Cooper Light', sans-serif !important;


// $weight-light: 200 !default;
// $weight-normal: 300 !default;
// $weight-medium: 400 !default;
// $weight-semibold: 500 !default;
// $weight-bold: 600 !default;

// recipe grid
$recipe-min-width-desktop: 15rem;
$recipe-min-width-mobile: 10rem;

// modal style
$modal-background-background-color: white;
$modal-card-head-background-color: white;
$modal-card-head-border-bottom: 1px solid #eee;
$modal-card-foot-border-top: 1px solid #eee;
// $modal-card-body-padding: 0 20px 10px 20px;

// form style
$input-radius: 2px;
$input-border-color: rgba(36,28,21,0.3); 
$input-shadow: none;
$input-focus-box-shadow-size: 0 0 0 0.125em;
$input-focus-box-shadow-color: #eee;
$input-focus-border-color: #aaa;

// sidebar menu
$menu-item-radius: 0;
$menu-item-active-color: $primary;
$menu-item-active-background-color: $background-color-dark;
$menu-item-hover-background-color: $background-color-dark;
$menu-list-link-padding-left: 1.4em; // custom
$menu-list-link-padding: 0.5em 2.2em 0.5em $menu-list-link-padding-left;
// sliders
$slider-track-background: $primary;
// buttons
$button-focus-box-shadow-size: 0 0 0 0;
$button-focus-border-color: $grey-light;