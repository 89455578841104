.recipe-importer__suggestions {
  margin-top: $sp-size-l;
  //background-color: $background-color-secondary;
}
.recipe-importer__suggestions-links {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: $sp-size-xs;
  li {
    background-color: $white;
    padding: 0.8rem 1rem;
    border-radius: 5px;
  }
}