.tabbar {
  position: fixed;
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: $white;
  width: 100%;
  z-index: 100;
  border-top: 1px solid $border-color;
  a {
    color: $grey;
    &.is-active {
      color: $primary;
    }
  }
}
.tabbar__wrapper {
  display: flex;
  align-items: center;
  max-height: 16vh;
  min-height: 3.5rem;
}
.tabbar__item {
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 0;
  text-align: center;
}
.tabbar__item-label {
  font-size: 0.7rem;
  margin-top: -5px;
}
.tabbar__add-icon {
  font-size: 0.7rem;
  .fa-circle {
    color: $primary-light;
  } 
  .fa-plus {
    color: $primary;
  } 
  .icon-shadow {
    // ???
    text-shadow: 0px 2px 5px red;
  }
  
}
