.sidebar {
  height: 100%;
  position: relative;

}

.sidebar__dropdown {
  margin-top: $sp-size-m;
  .dropdown-trigger {
    width: 100%;
    margin: 0 15px;
  }
  .dropdown-menu {
    margin-left: 15px;
    .icon {
      vertical-align: middle;
      margin-right: $sp-size-xxs;
    }
  }
  .dropdown-item {
    padding-right: 2em;
    color: $white;
    &:hover {
      background-color: $grey-dark;
      color: $white;
    }
  }
  .dropdown-content {
    background-color: $grey-darker;
  }
}


.sidebar__add-button {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $primary;
  color: white;
  border: 1px solid $primary;
  cursor: pointer;
  padding: calc(0.5em - 1px) 0.6em;
  white-space: nowrap;
  -webkit-appearance: none;
  border-radius: 4px;
  font-size: 1rem;
  box-shadow: none;
  height: 2.5em;
  line-height: 1.5;
  user-select: none;
  &:hover {
    background-color: scale-color($primary, $lightness: -10%);
  }
  &:active {
    background-color: scale-color($primary, $lightness: -20%);
  }
  .sidebar__add-button__text {
    padding-left: 0.4rem;
  }
  .icon-left {
    display: flex;
    justify-content: flex-start;
    margin-right: auto;
  }
  .icon-right {
    display: flex;
    justify-content: flex-end;
  }
}
.sidebar__logo {
  padding: 0.7rem;
  font-size: $size-4;
  text-align: center;
  img {
    vertical-align: middle;
    width: 130px;
    :hover {
      color: $primary-dark;
    }
  }
}
.sidebar__menu {
  margin: 2rem 0;
}
.menu-list {
  line-height: 1.5;
  font-weight: bold;
  a.is-active {
    border-left: 5px solid $primary;
    padding-left: calc(#{$menu-list-link-padding-left} - 5px);
  }
  .icon {
    vertical-align: sub;
  }
  .menu-entry {
    padding-left: 0.5rem;
  }
}