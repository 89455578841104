///
// react-modal overwrite

.ReactModalPortal>div {
  //opacity: 0;
}
.ReactModalPortal .ReactModal__Overlay {
  // align-items: center;
  // display: flex;
  // justify-content: center;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal__overlay{
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

///
// bulma overwrite

.modal{
  @include tablet {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    outline: none;
  }
  @include mobile {
    top: -30%;
  }
}
.modal-card-foot {
  justify-content: flex-end !important;
}
.modal-close {
  background-color: rgba(10, 10, 10, 0.2)
}

///
// custom styles

.modal_title {
  margin: 0 0 $sp-size-m 0;
}

.modal_body {
  margin: 0 0 $sp-size-l 0;
  font-size: 2rem;
  font-weight: 300;
  word-break: break-all;;
}

.modal-content {
  padding: $sp-size-l;
  @media screen and (max-width: 400px) {
    padding: 2.2rem;
    max-height: calc(100vh - 100px);
    overflow: visible;
  }
}
.modal-background {
  @include tablet {
    border-radius: 6px;
  }
}