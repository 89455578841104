@import './recipe-toolbar';

.recipe {
  padding-bottom: $sp-size-xl;
  display: flex;
  flex-direction: column;
  @include tablet {
    padding: $sp-size-m $sp-size-m $sp-size-xl $sp-size-m;
  }
  .recipe--mobile-padding {
    @include mobile {
      padding-left: $sp-size-s !important;
      padding-right: $sp-size-s !important;
    }
  }
  section {
    max-width: 1280px;
  }
  .recipe__column--photo {
    min-width: 20rem;
    @include desktop {
      max-width: 27rem;
    }
  }
  .recipe__column--ingredients {
    min-width: 20rem;
    @include desktop {
      max-width: 24rem;
    }
  }

  @include mobile {
    .columns.is-variable .column {
      padding-left: 0;
      padding-right: 0;
    }
    .columns {
      margin-left: 0;
      margin-right: 0;
    }
  }
}


.recipe__header {
  flex-shrink: 0;
  .recipe__image {
    min-width: 15rem;
    border-radius: 5px;
    @include mobile {
      //margin: -0.85rem;
      //margin-bottom: 0.5rem;
      //padding: 0;
    }
    .recipe__image-img {
      width: 100%;
      object-fit: cover;
      // max-height needed when we display the original image after download
      max-height: 300px;
      @include desktop {
        border-radius: 5px;
      }
    }
  }
}

.recipe__title,
.recipe__subtitle,
.recipe__instruction_number {
  font-family: $font-title;
  font-weight: bold;
  &.recipe__subtitle {
    text-transform: uppercase;
    margin-bottom: $sp-size-m;
  }
}
.recipe__description {
  margin-bottom: $sp-size-s;
  font-style: italic;
  color: $grey;
  @include tablet {
    // show only 2 lines
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      // show all on hover
      display: block;
    }
  }

}
.recipe__time {
  max-width: 10rem;
  @include tablet {
    &.column {
      padding-left: 0.75rem !important;
    }
  }
}
.recipe__time_title {
  text-transform: uppercase;
  font-size: $size-7;
  font-weight: bold;
  color: $grey;
}
.recipe__time_content {
  font-family: $font-title;
}

.recipe__servings {
  text-align: right;
  font-weight: bold;
  display: inline;
  @media screen and (min-width: $desktop),
  screen and (max-width: $tablet) {
    // exclude tablet
    position: absolute;
    top: -0.2rem;
    right: 0;
  }
  .buttons .button {
    margin-bottom: 0 !important;
  }
}
.recipe__servings-buttons {
  display: inline;
  padding-left: $sp-size-s;
}

$ingredients-background: $background-color;
$ingredients-border: $border-color;
$ingredients-bold: $yellow-dark;
// $ingredients-background: $primary-light;
// $ingredients-border: $primary-light;
// $ingredients-bold: $primary-dark;
.recipe__ingredients {
  background-color: $ingredients-background;
  border: 3px solid $ingredients-border;
  padding: $sp-size-s;
  @include tablet {
    border-radius: 2px;
  }
  @include mobile {
    // margin: auto -0.85rem;
    // border: 0;
    border-left: 0;
    border-right: 0;
  }
  .recipe__ingredients-header {
    position: relative;
  }
  .recipe__servings-label{
    color: $ingredients-bold;
  }
  .recipe__ingredients-blocks > div:not(:last-child) {
    margin-bottom: $sp-size-m;
  }
  .recipe__ingredients-subtitle {
    font-weight: bold;
    margin-bottom: 0.4rem;
    color: $ingredients-bold;
  }
  .recipe__ingredients-list {
    @include desktop {
      font-size: 0.95rem;
    }
  }
  .recipe__ingredient {
    padding: 0.2rem 0;
    strong {
      color: $ingredients-bold;
    }
  }
  .recipe__ingredients__no-ingredients {
    text-align: center;
    color: $grey-light;
    .recipe__ingredients__no-ingredients-text {
      font-size: 1.1rem;
      font-style: italic;
      min-height: 100px;
    }
    .recipe__ingredients__no-ingredients-emoji {
      font-size: 3rem;
      min-height: 100px;
      line-height: 100px;
      font-family: $font-title;
    }
  }
  // .recipe__ingredients__no-ingredients {
  //   text-align: center;
  //   div {
  //     color: $grey-light;
  //     font-size: 1.1rem;
  //     font-style: italic;
  //     margin-bottom: $sp-size-m;
  //   }
  //   img {
  //     width: 70px;
  //     opacity: .4;
  //   }
  // }
}


.recipe__instructions {
  @include tablet {
    padding-top: 0;
  }
  .recipe__instructions-subtitle {
    font-weight: bold;
    margin-bottom: $sp-size-xs;
    &:nth-child(n+4) {
      border-top: 1px solid $border-color;
      padding-top: $sp-size-s;
    }
  }
  .recipe__instructions-list {
    margin-bottom: $sp-size-m;

    .recipe__instruction_number {
      width: 2.5rem;
      flex: none;
      font-weight: bold;
    }
  }
  .recipe__instructions__no-instructions {
    @include tablet {
      border: 3px solid $ingredients-border;
    }
    padding-top: 60px;
    padding-bottom: 22px;
    text-align: center;
    color: $grey-light;
    .recipe__instructions__no-instructions-text {
      font-size: 1.1rem;
      font-style: italic;
      min-height: 100px;
    }
    .recipe__instructions__no-instructions-emoji {
      font-size: 3rem;
      min-height: 100px;
      line-height: 100px;
      font-family: $font-title;
    }
  }
}

.recipe__no-recipe {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 10rem;
  //color: $yellow;
  height: 100vh;
  img {
    width: 20rem;
  }
  h2 {
    font-size: $size-5;
    margin-bottom: $sp-size-m;
    font-family: $font-title;
  }
}

.recipe-shared__modal {
  @include tablet {
    max-width: 450px;
    .modal-content {
      max-width: 450px;
    }
  }
  @include mobile {
    .modal-content {
      margin-top: $sp-size-xl;
    }
  }
  .recipe-shared__modal__logo {
    text-align: center;
    margin-bottom: $sp-size-m;
    img {
      width: 150px;
    }
  }
}

.recipe-link__modal {
  @include mobile {
    .has-addons {
      input, button {
        font-size: 0.8rem;
      }
    }
  }
}

