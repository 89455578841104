@import './recipe-list-gallery-item';

.recipe-list {
  @include tablet {
    padding: $sp-size-m calc(#{$sp-size-m} - #{$sp-size-xxs});
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($recipe-min-width-desktop, 1fr));
    grid-gap: 0.7rem 0.9rem;
  }
  @include mobile {
    padding: $sp-size-s calc(#{$sp-size-s} - #{$sp-size-xxs});
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($recipe-min-width-mobile, 1fr));
    grid-gap: 0.4rem 0.7rem;
    padding-bottom: 6rem;
  }
}

.recipe-list__no-results {
  padding: $sp-size-m calc(#{$sp-size-m} - #{$sp-size-xxs});
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 10rem;
  height: 100vh;
  img {
    width: 10rem;
    margin-bottom: $sp-size-m;
  }
  h2 {
    font-size: $size-5;
    font-weight: bold;
    font-family: $font-title;
  }
  p {
    color: $grey;
  }
}

// CSS Grid
// https://gridbyexample.com/
