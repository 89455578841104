.onboarding {
  font-size: 1.2rem;
  //font-weight: $weight-light;
  h1.title {
    font-size: 3rem;
  }
  height: 100%;
}
.onboarding__wrap {
  padding: $sp-size-s;
  max-width: 1000px;
  margin: 0 auto !important;
  .column {
    padding: 0;
  }
}
.onboarding__logo {
  font-size: $size-4;
  margin-bottom: $sp-size-xl;
}
.onboarding__content {
  padding:$sp-size-l $sp-size-l;
}
.onboarding__img-wrapper {
  position: relative;
}
.onboarding__img1 {
  position: absolute;
  top: 0;
  left: 0;
  height:600px;
  max-width: 2000px;
  box-shadow: 0 0 0 1px rgba(23, 24, 31, 0.1);
  background-color: $white;
  padding: 4px;
  border-radius: 9px;
}
.onboarding__separator {
  position: relative;
  padding: 4rem;
  margin-top: 4rem;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120vw;
    height: 100%;
    transform: translate(-50%, -50%) rotate(3deg) skew(3deg);
    background: $background-color-secondary;
    z-index: -1;
  }
}
.onboarding__suggestions {
  background: $background-color-secondary;
  z-index: 1;
  padding-top: 0;
  padding-bottom: $sp-size-xl;
  margin-top: -4.1rem;
  height:100%;
}
.onboarding__suggestions-links {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: $sp-size-xs;
  li {
    background-color: $white;
    //box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);
    padding: 0.6rem 1rem;
    border-radius: 5px;
  }
}