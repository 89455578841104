.app-logo {
  color: $primary;
  font-weight: bold;
  font-family: $font-title;
}
.title {
  font-family: $font-title;
}
.center {
  display: flex;
  justify-content: center;
}
.center-v {
  display: flex;
  align-items: center;
}
.text-center {
  text-align: center;
}
.is-link {
  cursor: pointer;
}
.icon-size-m {
  font-size: 1.2rem;
}
.input--grey {
  background-color: $white-ter;
  border: none;
}
.has-text-yellow {
  color: $yellow;
}
button, .button {
  outline: none;
  transition-duration: .15s;
  -webkit-transition-duration: .15s;
  transition-property: background-color, color;
  -webkit-transition-property: background-color, color;
  transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
}
.button-as-link {
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: $primary;
  background: none;
  font-size: 1rem;
  &:hover {
    color: $primary-dark;
  }
}
.button-styleless {
  outline: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

// layout
html, body, #root {
  height: 100%;
}
.default-layout {
  section {
    padding: $sp-size-l 0;
  }
}
.default-layout__wrap {
  @include tablet {
    margin-left: $sp-size-l;
    margin-right: $sp-size-l;
    max-width: 800px;
  }
  @include mobile {
    margin-left: $sp-size-s;
    margin-right: $sp-size-s;
  }
}
.default-layout__wrap-fullpage {
  max-width: none;
  margin-right: $sp-size-l;
}
.bg-1 {
  background-color: $background-color;
}
.bg-2 {
  background-color: $background-color-secondary;
}
.has-toolbar {
  margin-top: $toolbar-height;
  overflow: auto;
}

.box--flat {
  box-shadow: none;
  border: 3px solid whitesmoke;
}

// input sizes
.control.input-w-xs {
  width: 5rem;
  @include desktop {
    &.is-expanded
    {
      flex-grow: 0!important;
    }
  }
}
.control.input-w-s {
  width: 15rem;
  @include desktop {
    &.is-expanded
    {
      flex-grow: 0!important;
    }
  }
}
.control.input-w-m {
  width: 25rem;
  @include desktop {
    &.is-expanded
    {
      flex-grow: 0!important;
    }
  }
}
.control.input-w-l {
  width: 40rem;
  @include desktop {
    &.is-expanded
    {
      flex-grow: 0!important;
    }
  }
}

// form
// fix the not visible disabled input on ios
input[disabled], fieldset[disabled], textarea[disabled] {
  -webkit-text-fill-color: $input-disabled-color;
  opacity: 1;
}
// fix the not visible "cancel text" icon on ios
input[type="search"]::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;
  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;
  /* icon size */
  background-size: 14px;
}
.control.has-icons-right .icon.show-password {
  pointer-events: inherit;
  cursor: pointer;
}

// Matetial UI Forms
.has-icons-left {
  .MuiInputBase-root,
  .MuiInputBase-root > fieldset, 
  .MuiFormLabel-root {
    padding-left: 25px !important; 
  }
}

.material-form-filled {
  input, textarea {
    color: $grey-darker;
  }
  .MuiFilledInput-root, 
  .MuiFilledInput-root.Mui-focused, 
  .MuiFilledInput-root:hover {
    border-radius: 0;
    background-color: #f6f6f6;
    font-family: $family-sans-serif;
  }
  .MuiFilledInput-underline {
    &:before {
      border-bottom: 1px solid $grey-lighter;
    }
    &:after {
      border-bottom: 2px solid $primary;
    }
  }
  .MuiFormLabel-root.Mui-focused { 
    color: $primary
  }
}

