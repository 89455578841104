.recipe-list-item__card {
  color: black;
  margin-bottom: 0.5rem;
}
.recipe-list-item__card-image {
  position: relative;
  box-shadow: 0 1px 6px rgba(25,25,34,.16);
  -webkit-box-shadow: 0 1px 6px rgba(25,25,34,.16);
  @include tablet {
    min-width: $recipe-min-width-desktop;
    min-height: calc(#{$recipe-min-width-mobile} * 340 / 600);
  }
  @include mobile {
    min-width: $recipe-min-width-mobile;
    min-height: calc(#{$recipe-min-width-mobile} * 340 / 600);
  }
  background: #eee;
  .img {
    border-radius: 5px;
    display: block;
    transition-duration: .3s;
    -webkit-transition-duration: .3s;
    transition-property: opacity;
    -webkit-transition-property: opacity;
    transition-timing-function: ease-out;
    -webkit-transition-timing-function: ease-out;
  }
  .img::before {
     content: "";
     display: block;
     height: 0;
  //   width: 0;
     padding-bottom: calc(9/16 * 100%);
  }
  &:hover .img {
    opacity: .8;
  }
  .recipe-list-item__card-time {
    position: absolute;
    bottom: 0.3rem;
    right: 0.3rem;
    opacity: 0.9;
  }
  .recipe-list-item__favorite {
    position: absolute;
    bottom: 0.3rem;
    left: 0.3rem;
    font-size: 100%;
    border: 0;
    border-radius: 300000px;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,.25);
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.25);
    opacity: .6;
    color: grey;
    transition-duration: .15s;
    -webkit-transition-duration: .15s;
    transition-property: opacity, color;
    -webkit-transition-property: opacity, color;
    transition-timing-function: ease-out;
    -webkit-transition-timing-function: ease-out;
    cursor: pointer;
    padding: 0.2rem 0.2rem 0.19rem 0.2rem;
    background-color: white;
    &:focus {
      outline:0;
    }
    &:hover {
      opacity: 1;
      color: $black;
    }
  }
}
.recipe-list-item__card-content {
  .h2 {
    font-family: $font-title;
    //font-size: $size-6;
    font-size: 1.1rem;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-top: 0.3rem;
    line-height: 1.5rem;
    @include mobile {
      line-height: 1.4rem;
    }
  }
}