// fonts primary
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

// fonts primary
// @font-face {
//   font-family: "Neue Haas Grotesk";
//   src: url("./fonts/Neue-Haas-Grotesk-55Rg.ttf")   format("truetype");
// } 
// font secondary
// @font-face {
//   font-family: "Cooper";
//   src: url("./fonts/cooper-bt-medium.ttf")   format("truetype");
// } 
@font-face {
  font-family: "Cooper Light";
  src: url("./fonts/cooper-bt-light.ttf")   format("truetype");
} 




// font secondary
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Bulma initial variables
@import '../../node_modules/bulma/sass/utilities/initial-variables';
@import '../../node_modules/bulma/sass/utilities/functions';
// Bulma derived variables
@import './base/variables';
// Add new color variables to the color map.
@import '../../node_modules/bulma/sass/utilities/derived-variables';

// bulma framework
@import '../../node_modules/bulma/bulma.sass';
@import '../../node_modules/bulma-slider/dist/css/bulma-slider.min.css';

// base styling
@import './base/base';
@import './base/spacing';

// common components
@import './common/toolbar';
@import './common/sidebar';
@import './common/tabbar';
@import './common/loader';

// components
@import './components/app';
@import './components/onboarding';
@import './components/recipe';
@import './components/recipe-list-gallery';
@import './components/recipe-list-table';
@import './components/recipe-import';
@import './components/recipe-edit';
@import './components/modal';
@import './components/signin-page';
@import './components/signin-form';
@import './components/settings';
@import './components/fullpage-loader';
@import './components/404';






