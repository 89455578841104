.settings {
  .box {
    padding-top: $sp-size-l;
    padding-bottom: $sp-size-xl;
    position: relative;
  }

  .version {
    position: absolute;
    bottom: $sp-size-xxs;
    right: $sp-size-xxs;
    color: $grey-light;
    font-size: 0.7rem;
  }
}