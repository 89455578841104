.recipe-edit {
  padding-bottom: $sp-size-xl;
  textarea {
    font-family: helvetica, arial !important;
    font-size: .9rem;
  }
  
  form {
    max-width: 650px;
  }
  .recipe-edit__title {
    input {
    font-size: $size-4;
    font-weight: bold;
    font-family: $font-title;
    }
  }
  .recipe-edit__description {
    font-style: italic;
  }
  .recipe-edit__ingredients, 
  .recipe-edit__instructions {
    position: relative;
    textarea {
      min-height: 6rem !important;
    }
  }
  .recipe-edit__help-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    box-shadow: 0px 3px 7px -1px rgba(63, 81, 181, 0.20);
  }
}
.recipe-edit__help-modal {
  .textarea {
    min-height: 15rem;
  }
}
.photo-upload {
  .control {
    display: flex;
  }
  .photo-upload__preview {
    margin-right: $sp-size-s;
    position:relative;
    img {
      cursor: pointer;
      width: 100px;
      height: 100px;
      object-fit: cover;
      box-shadow: 0 1px 6px rgba(25, 25, 34, 0.16);
      -webkit-box-shadow: 0 1px 6px rgba(25, 25, 34, 0.16);
    }
    &:hover img {
      opacity: .8;
    }
    &:active img {
      opacity: .6;
    }
    .loader-wrapper {
      width: 100px;
      height: 100px;
      margin-bottom: 6px;
      background: #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 1px 6px rgba(25, 25, 34, 0.16);
      -webkit-box-shadow: 0 1px 6px rgba(25, 25, 34, 0.16);
      .loader {
        height: 40px;
        width: 40px;
      }
    }
  }
  .photo-upload__icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    opacity: .8;
  }
  .file.is-boxed .file-cta {
    padding: 1em 1.5em;
    height: 100px;
    justify-content: center;
    .file-label {
      font-size: .9rem;
    } 
  }
  .disabled .file-cta {
    color: $grey-lighter;
    border-color: $grey-lightest;
    background-color: whitesmoke;
    cursor: no-drop;
    :hover {
      background-color: whitesmoke;
    }
    .file-label {
      cursor: no-drop;
    }
  }
}

