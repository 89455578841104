.recipe-table-item {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid white;
  &:nth-child(odd) {
    background-color: $background-color-secondary;
  }
  &:hover {
    background-color: scale-color($background-color-secondary, $lightness: -5%);
  }
  td {
    vertical-align: middle;
    padding: 0.7rem 1rem;
  }
}
.recipe-table-item__td-img {
  width: 6rem;
  min-width: 6rem;
  padding: 0 !important;
  img, svg {
    display: table-row;
  }
}
.recipe-table-item__td-title {
  font-family: $font-title;
  //min-width: 20rem; 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  a {
    color: $black;
  }
}
.recipe-table-item__td-time {
  width: 100px;
  max-width: 100px;
}
.recipe-table-item__time {
  opacity: 0.9;
}
.recipe-table-item__td-favorite {
  text-align: center;
  width: 70px;
  max-width: 70px;
}
.recipe-table-item__favorite {
  margin: auto;
  opacity: 0.9;
  font-size: 100%;
  border: 0;
  border-radius: 300000px;
  cursor: pointer;
  padding: 0.2rem;
  background-color: rgba(255, 255, 255, 0.5);
  &:focus {
    outline:0;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

