.app__sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11; // over the toolbar to see the shadow border
  height: 100%;
  overflow-x: hidden; /* Disable horizontal scroll */
  width: $sidebar-width;
  //border-right: 1px solid $border-color;
  background-color: $background-color;
  box-shadow: -1px 0 0 #d6dde3, 1px 0 0 #d6dde3, 0 1px 0 #d6dde3;
  -webkit-box-shadow: -1px 0 0 #d6dde3, 1px 0 0 #d6dde3, 0 1px 0 #d6dde3;
  @media screen and (max-width: $tablet) {
    display: none;
  }
}
.app__content {
  height: 100%;
  @media screen and (max-width: $tablet) {
    margin-left: auto;
  }
}
.app__content--has-sidebar {
  @include tablet {
    margin-left: $sidebar-width;
  }
}
