.signin-form {
  margin-bottom: $sp-size-s;
  .content {
    flex-grow: 1;
    padding: $sp-size-ml;
    padding-bottom: 10rem;
    width: 100%;
    margin: 0 30px;
  }
  .field {
    padding-bottom: 0.2rem;
  }
  .button {
    width: 100%;
  }
  .forgot {
    text-align: right;
    font-size: .8rem;
    color: $primary;
    padding-top:  $sp-size-xxs;
  }
  .instructions {
    text-align: center;
    margin-bottom: $sp-size-s;
  }
}
