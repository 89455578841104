.toolbar {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: $sidebar-width;
  display: flex;
  align-items: stretch;
  min-height: $toolbar-height;
  border-bottom: 1px solid $border-color;
  background-color: rgba(255, 255, 255, 0.97);
  &.toolbar--nosidebar {
    left: 0;
  }
  @media screen and (max-width: $tablet) {
    left: 0;
  }
  .button.button--back {
    background-color: $white;
    color: $black;
  }
}
.toolbar-start, 
.toolbar-end {
  align-items: stretch;
  display: flex;
  margin-right: $sp-size-xs;
}
.toolbar-start {
  justify-content: flex-start;
  margin-right: auto;
  margin-left: 0.5rem;
}
.toolbar-end {
  justify-content: flex-end;
  margin-left: auto;
}
.toolbar-item {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.5rem 0.75rem;
}

.button--toolbar {
  /* reset button */
  .toolbar-button__text {
    
  }
}
.button.is-icon {
  padding: 0 16px;
}
.button.button--back {
  padding: 0px 17px 0 15px;
  margin-left: -6px;
}

.toolbar__logo {
  img {
    width: 130px;
    vertical-align: middle;
  }
}

.toolbar__search {
  .input {
    background-color: $background-color;
    border: none;
    padding-bottom: 10px;
    @include tablet {
      width: 20rem;
    }
    @include mobile {
      width: 15rem;
    }
    &::placeholder {
      color: $grey;
    } 
  }
}


