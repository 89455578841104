.page404 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 10rem;
  background-color: $primary-light;
  color: $primary;
  height: 100vh;
  img {
    width: 20rem;
  }
  h1 {
    font-size: $size-3;
    margin-bottom: $sp-size-m;
    font-family: $font-title;
  }
}