// Color
$color1: $primary;
$color2: $yellow;
$color3: #0d3b66;
$color4: $primary-dark;

.bubble-loader {
 
   // Helper for center element
  @mixin centerChildren(){
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  // A movement type loader, use translate and transform
  .bubbles {
    min-width: 33%;
    @include centerChildren();
    
    .wrapper {
      $size: 80px;
      $ballSize: $size/5;
      width: $size;
      @include centerChildren();
      position: relative;

      * {
        position: absolute;
        border-radius: 50%;
        width: $ballSize;
        height: $ballSize;
      }

      $animation-speed: 1.6;
      $interval: $animation-speed/4;

      .ball1 {
        background-color: $color1;
        animation: movement $animation-speed + s $interval * 0 + s linear infinite;
      }
      .ball2 {
        background-color: $color2;
        animation: movement $animation-speed + s $interval * -1 + s linear infinite;
      }
      .ball3 {
        background-color: $color3;
        animation: movement $animation-speed + s $interval * -2 + s linear infinite;
      }
      .ball4 {
        background-color: $color4;
        animation: movement $animation-speed + s $interval * -3 + s linear infinite;
      }

      @keyframes movement {
        from { transform: scale(0); left: 0%; z-index: -1 }
        25% {  transform: scale(1); left: 0%; }
        30% {  transform: scale(1); left: 0%; }
        50% {  transform: scale(1); left: 25%; }
        55% {  transform: scale(1); left: 25%; }
        75% {  transform: scale(1); left: 50%; }
        80% {  transform: scale(1); left: 50%; }
        100% { transform: scale(0); left: 75%; z-index: 9 }
      }
    }
  }
}
