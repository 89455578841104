.signin {
  height: 100vh;
  margin: auto;
  h1 {
    font-family: 'Cooper Light' !important;
    font-size: 2.4rem;
    text-align: center;
    padding-bottom: $sp-size-s;
  }
}
.signin__logo {
  text-align: center;
  img {
    vertical-align: middle;
    width: 150px;
  }
}
.signin__intro {
  font-size: 1rem;
  max-width: 28rem;
  margin: auto;
  margin-bottom: $sp-size-m;
  text-align: center;
  @include mobile {
    font-size: .9rem;
  }
}
.signin__content {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @include desktop {
    flex: none;
    width: 38%;
  }
  @include touch {
    margin: auto !important;
  }
  .content {
    margin: 0 $sp-size-ml;
    max-width: 22rem;
    min-width: 20rem;
    @media screen and (max-width: 500px) {
      min-width: 80%;
    }
  }
}

.signin__illustration {
  background-color: $primary-light;
  background-image: url("/images/fruits-4.jpg");
  background-size: cover;
  @media screen and (max-width: $tablet) {
    display: none;
  }
  &.signin__illustration--register {
    background-image: url("/images/fruits-1.jpg");
  }
}
.signin__register-link-wrapper {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
}
.signin__register-link {
  @media screen and (max-width: $tablet) {
    text-align: center;
    display: block;
  }

}
